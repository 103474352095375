import React, { ReactNode } from 'react';
import { GlobalStyles } from '../GlobalStyle';

interface IProps {
  children: ReactNode;
}

export default function Layout({ children }: IProps) {
  return (
    <>
      <GlobalStyles />
      {children}
    </>
  );
}
