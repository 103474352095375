import { createGlobalStyle } from "styled-components";
import GraphikBoldWoff2 from "./fonts/Graphik/Graphik-Bold-Web.woff2";
import GraphikBoldWoff from "./fonts/Graphik/Graphik-Bold-Web.woff";
import GraphikRegularWoff2 from "./fonts/Graphik/Graphik-Regular-Web.woff2";
import GraphikRegularWoff from "./fonts/Graphik/Graphik-Regular-Web.woff";
import GraphikMediumWoff2 from "./fonts/Graphik/Graphik-Medium-Web.woff2";
import GraphikMediumWoff from "./fonts/Graphik/Graphik-Medium-Web.woff";
import FKScreamerBlackWoff2 from "./fonts/FkScreamer/FKScreamer-Black.woff2";
import FKScreamerBlackWoff from "./fonts/FkScreamer/FKScreamer-Black.woff";

export const GlobalStyles = createGlobalStyle`
	:root {
		--font-size: 10px;
		--layout-maxWidth: 92vw;
	}    
	html {
		font-size: var(--font-size);
	}
	/*
	    This font software is the property of Commercial Type.
	
	    You may not modify the font software, use it on another website, or install it on a computer.
	
	    License information is available at http://commercialtype.com/eula
	    For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com
	
	    Copyright (C) 2019 Schwartzco Inc.
	    License: 1904-ZUVKZX 
	    License: 1904-ASQXDV       
	*/
	
	
	/* Graphik Bold */
	@font-face {
	  font-family: 'Graphik Web';
	  src: url('${GraphikBoldWoff2}') format('woff2'),
	       url('${GraphikBoldWoff}') format('woff');
	  font-weight:  600;
	  font-style:   normal;
	  font-stretch: normal;
	}
	.Graphik-Bold-Web {
	  font-family: 'Graphik Web';
	  font-weight:  600;
	  font-style:   normal;
	  font-stretch: normal;
	}
	/* Graphik Regular */
	@font-face {
	  font-family: 'Graphik Web';
	  src: url('${GraphikRegularWoff2}') format('woff2'),
	       url('${GraphikRegularWoff}') format('woff');
	  font-weight:  400;
	  font-style:   normal;
	  font-stretch: normal;
	}
	.Graphik-Regular-Web {
	  font-family: 'Graphik Web';
	  font-weight:  400;
	  font-style:   normal;
	  font-stretch: normal;
	}
	/* Graphik Medium */
	@font-face {
	  font-family: 'Graphik Web';
	  src: url('${GraphikMediumWoff2}') format('woff2'),
	       url('${GraphikMediumWoff}') format('woff');
	  font-weight:  500;
	  font-style:   normal;
	  font-stretch: normal;
	}
	.Graphik-Medium-Web {
	  font-family: 'Graphik Web';
	  font-weight:  500;
	  font-style:   normal;
	  font-stretch: normal;
	}
	
	/* FK Screamer Black 
		License information is available at http://simonamunteanu.com/fonts/FkScreamer/EULA.pdf
		Purchase from Florian Karsten Type Foundry
		Order ID: 38999095
	*/
	@font-face {
	  font-family: 'FK Screamer';
	  src: url('${FKScreamerBlackWoff2}') format('woff2'),
	  	 url('${FKScreamerBlackWoff}') format('woff')	;
	  font-weight:  900;
	  font-style:   normal;
	  font-stretch: normal;
	  font-display: block;
	}
	
	body {
		font-family: 'Graphik Web',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		line-height: 1.5;
	      -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;  
		font-size: 1.6rem;
		margin: 0;
		padding: 0;
		transition: all 0.1s ease-in-out;
		font-variant-ligatures: normal;
	}   
	body.hero-visible .page-nav {
		transform: translateY(7rem);
		transition: transform 0.2s ease-out;
	}
	body.hero-hidden .page-nav {
		transform: translateY(0);
		transition: transform 0.2s ease-out;
	}
	main {
		overflow-x:hidden;
	}
	a {
		color: inherit;
		text-decoration: none;
	}
	img {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		display: block;
	} 
	a, button {
		cursor: pointer
	}
	strong {
		font-weight: 500;
	}
	p, ul, ol {
		font-size: calc(1.6rem + 0.25vw);
		line-height: 1.6;
	}
	ul, ol {
		margin-block-start: 0.5rem;
	}
	li {
		margin-bottom: 0.4rem;
	}
	span { display: inline-block; }
	.uppercase {
		text-transform: uppercase;
	}
	.center {
		text-align: center;
	}
	.right {
		text-align: right;
	}
	.label > a {
		display: block;
		font-weight: 600;
		white-space: nowrap;
		background: #121212;
		color: #f3f3f3;
		padding: 1.6rem 3.2rem;
		border-radius: 99px;
		transform: scale(1);
		transition: transform 0.3s ease-in-out;
		-webkit-backface-visibility: hidden;
	}
	.label:hover > a {
		transform: scale(1.1);
	}
	.scroll {
	  position: absolute;
	  width: 100vw;
	  height: 100vh;
	  overflow-y: auto;
	  top: 0;
	  left: 0;
	}
	.gatsby-image-wrapper img {
		object-fit: cover;
	}
`;
